.anchor-style{
    //text-decoration: underline !important;
}

.card-grey{
    border: 1px solid;
    border-radius: 20px;
    border-color: #bdbfc1;
}

.icon-size-initial{
    font-size: initial !important;
}

.MuiLinearProgress-root{
    z-index: 9999999999999999999 !important;
    height: 0.6em !important;
}  

.login-screen-center{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
}

.centered-image{
    display: flex;
    justify-content: center; /* align horizontal */
    align-items: center; /* align vertical */
}

.p-4-ext-1 {
    padding: 0.5rem !important;
    text-align: -webkit-right;
    color: #FFF;
}

.p-4-ext-2 {
    margin-top: -7px;
    margin-bottom: -14px;
    color: #FFF;
}

.img-fluid-ext{
    max-width: 63% !important;
}

.policy-editable{
    display: flex;
}

.align-items-baseline{
    margin-top: -0.3em;
    width: 14em;
}

.mr-1{
    margin-right: 0.25rem!important;
}

select.form-control { 
    -webkit-appearance: menulist; 
}

.expand-cell{
    cursor: pointer;
}

.disable-cursor{
    cursor: auto !important;
}

.anchor-dotted{
    text-decoration: underline dotted !important
}

.note-label {
    color: rgba(0, 0, 0, 0.6) !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    font-weight: 400 !important;
    font-size: 0.77rem !important;
    line-height: 1.66 !important;
    letter-spacing: 0.03333em !important;
    text-align: left !important;
    // margin-top: 3px;
    margin-right: 14px !important;
    // margin-bottom: 0;
    margin-left: 14px !important;
}

.validation {
    // display: flex;
    flex-direction: column;
    justify-content: space-around;
    box-sizing: border-box;
    margin-bottom: 1em;
    // display: block;
  }
  
  .validation-item {
    margin-bottom: 0.85em
  }
  
  .validation-icon {
    display: inline-block;
    margin-right: 0.5em;
    width: 1.25em;
    margin-top: 0.3em;
  }
  
  .validator {
    display: flex;
  }

  .success {
    color: #34c38f;
  }

  body {
    -ms-overflow-style: none !important; /* for Internet Explorer, Edge */
    scrollbar-width: none !important; /* for Firefox */
    // overflow-y: scroll !important; 
  }
  
  body::-webkit-scrollbar {
    display: none !important; /* for Chrome, Safari, and Opera */
  }

  .cursor-default{
    cursor: default !important;
  }
  
  .auth-main-win{
    margin-top: 1rem;
  }

  .idleModal{
    position: absolute !important;
    top:0 !important;
    left:0 !important;
    z-index: 100511 !important;
  }

// #fnol-form{
//     input[type="radio"] {
//       opacity: 0;
//       position: fixed;
//       width: 0;
//       &:checked + label {
//         background-color: $primary;
//         border: none;
//         color: $white;
//       }
//     }
//     // color option
//     &.coloropt-radio{
//       input[type="radio"] {
//         &:checked + label {
//           &:after{
//             content: "\F012C";
//             position: absolute;
//             font-family: "Material Design Icons";
//             color: $white;
//             font-size: 14px;
//             top: 50%;
//             left: 50%;
//             transform: translateX(-50%) translateY(-50%);
//           }
//           &.bg-light{
//             &:after{
//               color: $primary;
//             }
//           }
//         }
//       }
  
//       label{
//         border: 1px solid $card-bg !important;
//           &.bg-light{
//             background-color: $card-bg;
//             border-color: $primary !important;
//           }
//       }
//     }
  
//     // image option
//     &.imgopt-radio{
//       label{
//         padding: 0;
//         overflow: hidden;
//         background: transparent;
//       }
//       input[type="radio"] {
//         &:checked + label {
//           &::before{
//             content: "";
//             position: absolute;
//             left: 0;
//             top: 0;
//             width: 100%;
//             height: 100%;
//             background-color: rgba($primary, 0.6);
//           }
  
//           &:after{
//             content: "\F012C";
//             position: absolute;
//             font-family: "Material Design Icons";
//             color: $white;
//             font-size: 14px;
//             top: 50%;
//             left: 50%;
//             transform: translateX(-50%) translateY(-50%);
//           }
//         }
//       }
//     }
//   }